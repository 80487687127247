import styles from "./chatHistory.module.css";
//assets
import { FaEdit } from "react-icons/fa";
import Text from "../../Text";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllChats, getChatHistory, setNewChat } from "../../../store";
import Skeleton from "../../skeleton";
import { useAction } from "../../../hooks/useActions";

const ChatHistory = () => {
  const dispatch = useDispatch();
  const { allChats, activeChat } = useSelector((state) => state.chats);
  const { selectedStock } = useSelector((state) => state.stocks);
  const [getAllChatsAction, , isAllChatLoading] = useAction(getAllChats);

  useEffect(() => {
    getAllChatsAction({
      stock_ticker: selectedStock?.ticker,
    });
  }, [selectedStock]);

  const getMessagesHandler = (id) => {
    dispatch(
      getChatHistory({
        chat_id: id,
      })
    );
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <Text text={"Chats"} bold={true} />
        <FaEdit
          size={16}
          onClick={() => {
            dispatch(setNewChat());
          }}
          className={styles.pointer}
        />
      </div>
      {isAllChatLoading ? (
        <Skeleton
          width="100%"
          height="30px"
          gap="15px"
          customStyle={{
            marginTop: "20px",
          }}
        />
      ) : allChats?.length ? (
        <>
          {allChats?.map((item) => (
            <div className={styles.chats_wrapper} key={item?.monthValue}>
              <div className={styles.chat_month}>{item?.monthName} </div>
              <div className={styles.monthly_chats}>
                {item?.data?.map((dataItem) => (
                  <div
                    className={`${styles.chat_item} ${
                      dataItem?.chat_id === activeChat?.chat_id
                        ? styles.active_chat
                        : null
                    }`}
                    key={dataItem?.chat_id}
                    onClick={() => getMessagesHandler(dataItem?.chat_id)}
                  >
                    <Text
                      // text={removeMarkDown(dataItem?.auto_generated_name)}
                      text={dataItem?.auto_generated_name}
                      textStyle={{
                        textTransform: "capitalize",
                        maxWidth: "175px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className={styles.new_chat}>New Chat +</div>
      )}
    </div>
  );
};

export default ChatHistory;
