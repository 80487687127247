import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearActiveChat,
  getAllStockTickers,
  setSelectedStock,
} from "../../store";

const useChat = () => {
  const dispatch = useDispatch();
  const [isShowingPDF, setIsShowingPDF] = useState(false);
  const [fileLink, setFilLink] = useState("");
  const { selectedStock } = useSelector((state) => state.stocks);

  //changing the browser title
  useEffect(() => {
    document.title = "Munshot | Chat";
  }, []);

  useEffect(() => {
    dispatch(getAllStockTickers());
  }, []);

  const handleSearchStockName = (item) => {
    dispatch(setSelectedStock(item));
    dispatch(clearActiveChat());
  };

  const handleShowPDF = (link) => {
    setIsShowingPDF(true);
    setFilLink(link);
  };

  const handleClosePDF = () => {
    setIsShowingPDF(false);
    setFilLink("");
  };
  return {
    handleSearchStockName,
    handleShowPDF,
    isShowingPDF,
    fileLink,
    handleClosePDF,
    selectedStock,
  };
};
export default useChat;
