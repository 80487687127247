import React, { useState } from "react";
import { LuUploadCloud } from "react-icons/lu";
import { IoIosAdd } from "react-icons/io";
import { IoTrashBinSharp } from "react-icons/io5";
import styles from "../styles/uploadDocument.module.css";
import SelectionOption from "./SelectOption";
import { FILE_OPTIONS } from "../utils/constants/common";
import { uploadFiles } from "../store";
import { toast } from "react-toastify";
import SearchBar from "./SearchBar";
import { useDispatch } from "react-redux";
import { TIME_OPTIONS } from "../utils/constants/common";

const UploadDocument = ({ setSelectedOption }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => setModalVisible(true);

  return (
    <>
      <button onClick={handleOpenModal} className={styles.upload_btn}>
        <IoIosAdd />
      </button>

      {modalVisible && (
        <Modal
          setModalVisible={setModalVisible}
          setSelectedOption={setSelectedOption}
        />
      )}
    </>
  );
};

export default UploadDocument;

const Modal = ({ setModalVisible, setSelectedOption }) => {
  const [filesData, setFilesData] = useState([]);
  const [isDragging, setIsDragging] = useState(false); // For drag feedback
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setModalVisible(false);
    setFilesData([]);
  };

  const handleFileSelect = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf"; // Only allow PDF files
    input.multiple = true;
    input.onchange = (event) => {
      const selectedFiles = Array.from(event.target.files);
      processFiles(selectedFiles);
    };
    input.click();
  };

  // File validation logic
  const processFiles = (selectedFiles) => {
    // Filter to only keep PDF files
    const pdfFiles = selectedFiles.filter(
      (file) => file.type === "application/pdf"
    );

    if (pdfFiles.length !== selectedFiles.length) {
      toast.clearWaitingQueue();
      toast.dismiss();
      toast.error("Only PDF files are allowed.");
    }

    // Filter files by size limit
    const validFiles = pdfFiles.filter((file) => file.size <= 20 * 1024 * 1024);

    if (validFiles.length !== pdfFiles.length) {
      toast.clearWaitingQueue();
      toast.dismiss();
      toast.error(
        "Some files were not added due to exceeding the 20MB size limit."
      );
    }

    const newFilesData = validFiles.map((file) => ({
      file,
      type: "others",
      stock: null, // Initialize the stock field
    }));

    setFilesData((prevFilesData) => [...prevFilesData, ...newFilesData]);
  };

  const handleRemoveFile = (index) => {
    setFilesData((prevFilesData) =>
      prevFilesData.filter((_, i) => i !== index)
    );
  };

  const handleTypeChange = (index, value) => {
    setFilesData((prevFilesData) =>
      prevFilesData.map((data, i) =>
        i === index ? { ...data, type: value } : data
      )
    );
  };

  const handleSelectStock = (index, stock) => {
    setFilesData((prevFilesData) =>
      prevFilesData.map((data, i) =>
        i === index ? { ...data, stock: stock?.ticker } : data
      )
    );
  };

  const handleSubmit = async () => {
    try {
      // Validate files
      if (filesData.length === 0) {
        throw new Error("Please upload files");
      }

      // Check if any file is missing stock
      const isStockMissing = filesData.some((file) => !file.stock);
      if (isStockMissing) {
        throw new Error("Please add stock to all files before uploading.");
      }

      setIsUploading(true);

      await dispatch(uploadFiles(filesData)).unwrap();
      setFilesData([]);
      setModalVisible(false);
    } catch (error) {
      // Handle error (show toast)
      toast.clearWaitingQueue();
      toast.dismiss();
      toast.error(error.message || "Something went wrong during the upload.");
      if (error.statusCode) {
        setModalVisible(false);
        setFilesData([]);
      }
    } finally {
      setIsUploading(false);
      setSelectedOption(TIME_OPTIONS.ALL_TIME);
    }
  };

  // Drag and Drop Handlers
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true); // Show visual feedback
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false); // Remove visual feedback
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    processFiles(droppedFiles); // Handle dropped files
  };

  if (isUploading) {
    return (
      <div className={styles.loader_wrapper}>
        <div className={styles.loader_content}>
          <p>Files are uploading...</p>
          <div className={styles.loader}></div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${styles.modal}  ${isDragging ? styles.drag_active : ""}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          {filesData.length ? (
            <button className={styles.btn} onClick={handleFileSelect}>
              Add More
            </button>
          ) : (
            <h2 style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
              Upload File
            </h2>
          )}
          <span className={styles.close} onClick={handleCloseModal}>
            &times;
          </span>
        </div>

        {!filesData.length ? (
          <div className={styles.upload_box} onClick={handleFileSelect}>
            <div className={styles.upload_icon}>
              <div className={styles.upload_icon_inner}>
                <LuUploadCloud size={24} />
              </div>
            </div>
            <p>
              <strong className={styles.upload_text}>Click to upload</strong> or
              drag and drop <br />
              PDF, WORD, Excel (max. 20MB)
            </p>
          </div>
        ) : (
          <div className={styles.files_wrapper}>
            {filesData.map((data, index) => (
              <div key={index} className={styles.file_wrapper}>
                <p className={styles.file_name}>{data.file.name}</p>
                <div className={styles.file_options}>
                  <SearchBar
                    width="100%"
                    placeholder="Stock Name"
                    onSelect={(stock) => handleSelectStock(index, stock)}
                  />
                  <SelectionOption
                    options={FILE_OPTIONS}
                    selectedValue={data.type}
                    onChange={(value) => handleTypeChange(index, value)}
                  />
                  <IoTrashBinSharp
                    color="gray"
                    size={25}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveFile(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={styles.submit_btn}>
          <button className={styles.btn} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
