import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import { toast } from "react-toastify";
import {
  updateStreamingMessage,
  finalizeStreamingMessage,
} from "../slices/chatsSlice";

export const sendMessage = createAsyncThunk(
  "chats/sendMessage",
  async ({ message }, { dispatch, rejectWithValue, getState }) => {
    const token = getState().user.token;
    const chat_id = getState().chats?.activeChat?.chat_id;
    const { selectedDocs, selectedCategories } = getState().docs;
    const { selectedStock } = getState().stocks;
    const url = apiEndpoints.SEND_CHAT_MESSAGE;

    if (!message) {
      return rejectWithValue("Message is required");
    }
    let isNewChat = false;
    if (chat_id == null) {
      isNewChat = true;
    }
    if (isNewChat) {
      if (
        selectedStock == null &&
        !selectedDocs.length &&
        !selectedCategories.length
      ) {
        toast.clearWaitingQueue();
        toast.dismiss();

        toast.error("Please select a stock ticker or document");
        return rejectWithValue(
          "Please select a document or stock ticker rejectWithValue"
        );
      }
    }

    const body = {
      chat_id,
      message,
      stock_ticker: selectedStock?.ticker,
      document_ids: selectedDocs,
      categories: selectedCategories,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      // const result = await api.post(url, body, { headers });
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Failed to send message");
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let partialMessage = "";
      // Read the stream data
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split("\n").filter((line) => line.trim() !== "");

        for (const line of lines) {
          if (line.startsWith("data:")) {
            const eventData = JSON.parse(line.substring(5));

            if (eventData.event === "token") {
              partialMessage += eventData.data;
              dispatch(
                updateStreamingMessage({ content: partialMessage, isNewChat })
              );
            }

            if (eventData.event === "end") {
              return dispatch(
                finalizeStreamingMessage({ isNewChat, data: eventData.data })
              );
            }
          }
        }
      }
    } catch (error) {
      toast.clearWaitingQueue();
      toast.dismiss();

      toast.error(
        error.response?.data?.message ||
          error.message ||
          "Failed to send message"
      );
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to send message"
      );
    }
  }
);
