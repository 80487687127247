import React, { useEffect, useRef, useState, useCallback } from "react";
import "../../../styles/bounce.css";
import SuggestionBox from "../suggestionBox";
import { BsSendFill } from "react-icons/bs";
import { PiSparkleFill } from "react-icons/pi";
import styles from "./chatWindow.module.css";
import { useSelector, useDispatch } from "react-redux";
import remarkGfm from "remark-gfm";

import {
  sendMessage,
  addUserMessage,
  getSuggestedQuestions,
} from "../../../store";
import Skeleton from "../../skeleton";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";

const ChatWindow = () => {
  const dispatch = useDispatch();

  const {
    activeChatMessages,
    activeChatId,
    isChatMessagesLoading,
    isMessageSending,
  } = useChatSelectors();
  const { selectedStock } = useSelector((state) => state?.stocks);
  const { selectedDocs, selectedCategories } = useSelector(
    (state) => state?.docs
  );

  const [input, setInput] = useState("");
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const messagesEndRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const fetchSuggestedQuestions = async () => {
    try {
      const suggestions = await dispatch(
        getSuggestedQuestions(activeChatId)
      ).unwrap();
      setSuggestedQuestions(suggestions);
    } catch (error) {
      toast.error("Failed to fetch suggested questions");
    }
  };

  const handleSendMessage = async (messageContent) => {
    setSuggestedQuestions([]);
    const userMessage = createUserMessage(messageContent);
    dispatch(addUserMessage(userMessage));

    try {
      await dispatch(sendMessage({ message: messageContent })).unwrap();
      if (activeChatId) {
        fetchSuggestedQuestions();
      }
    } catch (error) {
      toast.error("Something went wrong while sending the message");
    }
  };

  const handleSubmit = () => {
    if (input.trim()) {
      handleSendMessage(input);
      setInput("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSuggestionClick = (suggestion) => {
    if (suggestion.trim()) {
      handleSendMessage(suggestion);
    }
  };

  const isInputDisabled =
    (selectedStock == null &&
      selectedDocs?.length === 0 &&
      selectedCategories.length === 0) ||
    isMessageSending;

  useEffect(() => {
    scrollToBottom();
  }, [activeChatMessages, scrollToBottom]);

  useEffect(() => {
    setSuggestedQuestions([]);
    if (activeChatId) {
      fetchSuggestedQuestions();
    }
  }, [activeChatId]);

  return (
    <div className={styles.chat_container}>
      <MessageList
        messages={activeChatMessages}
        isLoading={isChatMessagesLoading}
        isMessageSending={isMessageSending}
        messagesEndRef={messagesEndRef}
      />
      <div className={styles.chat_controls}>
        <SuggestionBox
          questions={suggestedQuestions}
          onItemPressHandler={handleSuggestionClick}
        />
        <div className={styles.prompt}>
          <span className={styles.left}>
            <PiSparkleFill color="#4F46E5" />
          </span>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder={
              isInputDisabled
                ? "Please select stock ticker or documents"
                : "Ask Questions"
            }
            onKeyDown={handleKeyPress}
            disabled={isInputDisabled}
            style={{
              cursor: isInputDisabled ? "not-allowed" : "text",
            }}
          />
          <span className={styles.right} onClick={handleSubmit}>
            <BsSendFill color="#4F46E5" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;

const MessageList = ({
  messages,
  isLoading,
  isMessageSending,
  messagesEndRef,
}) => (
  <div className={styles.chat_messages}>
    {isLoading
      ? Array.from({ length: 20 }).map((_, index) => (
          <Skeleton
            key={index}
            height={index % 2 === 0 ? "50px" : "200px"}
            width="100%"
            count={1}
            customStyle={{
              marginLeft: index % 2 === 0 ? "auto" : "0px",
              marginTop: "10px",
              width: "70%",
            }}
          />
        ))
      : messages?.map((msg, index) => (
          <ChatMessage
            key={index}
            index={index}
            msg={msg}
            isBot={msg.role === "bot"}
          />
        ))}
    {isMessageSending && (
      <ChatMessage
        index={messages?.length || 0}
        msg={{ content: "Loading...", role: "bot" }}
        isBot={true}
      />
    )}
    <div ref={messagesEndRef} />
  </div>
);

const ChatMessage = ({ index, msg, isBot }) => (
  <div
    key={index}
    className={`${styles.chat_message} ${isBot ? styles.bot : styles.user}`}
  >
    {isBot ? <Answer msg={msg} /> : <Question msg={msg} />}
  </div>
);

const Answer = ({ msg }) => (
  <div className={styles.answer}>
    <div className={styles.answer_head}>
      <span>Answer</span>
    </div>
    <ReactMarkdown
      className={styles.reactMarkDown}
      remarkPlugins={[remarkGfm]}
      children={msg.content}
    />
  </div>
);

const Question = ({ msg }) => (
  <div className={styles.question}>{msg.content}</div>
);

const createUserMessage = (content) => ({
  content,
  message_id: `user-${Date.now()}`,
  role: "user",
  timestamp: new Date().toISOString(),
});

const useChatSelectors = () => {
  return {
    activeChatMessages: useSelector(
      (state) => state?.chats?.activeChat?.messages
    ),
    activeChatId: useSelector((state) => state?.chats?.activeChat?.chat_id),
    isChatMessagesLoading: useSelector(
      (state) => state?.chats?.isChatMessagesLoading
    ),
    isMessageSending: useSelector((state) => state?.chats?.isMessageSending),
  };
};
